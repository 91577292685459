import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Test Week starts today!`}</strong></p>
    <p>{`Deadlifts 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`400M Run`}</p>
    <p>{`21-Deadlifts (225/155)`}</p>
    <p>{`42-Wall Balls (20/14)`}</p>
    <p>{`15-Deadlifts`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`9-Deadlifts`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`400M Run`}</p>
    <p>{`For time. `}<em parentName="p">{` `}{`*`}{`compare to 2/10/17`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`Test 1`}{`*`}{`*`}</strong></p>
    <p>{`1 attempt, max unbroken T2B`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Test 2`}{`*`}{`*`}</strong></p>
    <p>{`50 Calorie Assault Bike for time.`}</p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start April 8th.  Contact Eric at
fallscitystrength\\@gmail.com for more info.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      